import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ChargeRepaySubscription } from '@chargeRepay/charge-repay.interfaces'
import {
  AvailableEndpointMetricsResponse,
  BillingAccount,
  BillingStoreErrorState,
  BookingPackage,
  DeviceInfo,
  IaSubscription,
  ImpulsePackagesResponseItem,
  SimplePackage,
  SubscriptionResponse,
  TsdPackagesResponseItem,
  TsdSubscription,
} from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription } from 'rxjs'
import { BillingHttpService } from '../backend/billing-http.service'

@Injectable({
  providedIn: 'root',
})
export class BillingStore {
  // Internal error flag
  private errored = false

  // Note: This is for externally flagging that an error has occurred on a data fetch
  // This is necessary because it could happen at any time and we need to know in components when we should not clear error status messages
  public dataFetchError = false

  // We cannot call error() on our data streams because this causes them to close.
  // Instead we have a dedicated error stream which gives an internal status of the whole store.
  private _errorState$: Subject<BillingStoreErrorState> = new Subject()

  // Private subjects

  // Note: I don't like the null thing. This pattern doesn't play nicely with single objects.
  private _billingAccount$: BehaviorSubject<BillingAccount | null> = new BehaviorSubject(null)

  private _allSubscriptions$: BehaviorSubject<{
    tsdSubscriptions?: TsdSubscription[]
    iaSubscriptions?: IaSubscription[]
    chargeRepaySubscriptions?: ChargeRepaySubscription[]
    emsSubscriptions?: SubscriptionResponse[]
    allSubscriptions?: SubscriptionResponse[]
  }> = new BehaviorSubject({})

  private _allAvailablePackages$: BehaviorSubject<{
    tsdPackages: TsdPackagesResponseItem[]
    iaPackages: ImpulsePackagesResponseItem[]
    emsPackages: BookingPackage[]
    crsPackages: BookingPackage[]
  }> = new BehaviorSubject({
    tsdPackages: [],
    iaPackages: [],
    emsPackages: [],
    crsPackages: [],
  })

  private _availableEndpointMetrics$: BehaviorSubject<AvailableEndpointMetricsResponse | null> = new BehaviorSubject(null)

  // Public subjects
  public readonly billingAccount$ = this._billingAccount$.asObservable()
  public readonly allSubscriptions$ = this._allSubscriptions$.asObservable()
  public readonly allAvailablePackages$ = this._allAvailablePackages$.asObservable()
  public readonly availableEndpointMetrics$ = this._availableEndpointMetrics$.asObservable()

  public readonly errorState$ = this._errorState$.asObservable()

  // Subcriptions
  private organisationSwitchBegunSubscription: Subscription
  private organisationsListedSubscription: Subscription
  private organisationSwitchedSubscription: Subscription

  // Flags
  private billingAccountInitialised = false
  private allSubscriptionsInitialised = false
  private allAvailablePackagesInitialised = false
  private availableEndpointMetricsInitialised = false
  public storeInitialised$ = new BehaviorSubject<boolean>(true)

  constructor(
    private billingHttpService: BillingHttpService,
    private proficloud: ProficloudService
  ) {
    // Subscribe to relevant observables
    this.organisationSwitchBegunSubscription = this.proficloud.organisationSwitchBegun$.subscribe(() => {
      this.reset()
    })

    this.organisationsListedSubscription = this.proficloud.organisationsListed$.subscribe(() => {
      // TODO: Anything?
    })

    this.organisationSwitchedSubscription = this.proficloud.organisationSwitched$.subscribe(() => {
      this.reload()
    })

    this.reload()
  }

  private reload() {
    this.reset()

    this.loadBillingAccount()
    this.loadAllSubscriptions()
    this.loadAllAvailablePackages()
  }

  private reset() {
    this.billingAccountInitialised = false
    this.allSubscriptionsInitialised = false
    this.allAvailablePackagesInitialised = false
    this.availableEndpointMetricsInitialised = false
  }

  // Loading methods

  private loadBillingAccount() {
    this.billingHttpService.getBillingAccount().subscribe({
      next: (res) => {
        this._billingAccount$.next(res)
        this.billingAccountInitialised = true
        this.emitStoreInitialised()
      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
      },
    })
  }

  private loadAllSubscriptions() {
    forkJoin([
      this.billingHttpService.listTsdSubscriptions(),
      this.billingHttpService.listIaSubscriptions(),
      this.billingHttpService.listChargeRepaySubscriptions(),
      this.billingHttpService.getEmsSubscriptions(),
      this.billingHttpService.listAllSubscriptions(),
    ]).subscribe({
      next: (res) => {
        this._allSubscriptions$.next({
          tsdSubscriptions: res[0] || [],
          iaSubscriptions: res[1] || [],
          chargeRepaySubscriptions: res[2] || [],
          emsSubscriptions: res[3] || [],
          allSubscriptions: res[4] || [],
        })
        this.allSubscriptionsInitialised = true
        this.emitStoreInitialised()
      },
      error: (err) => {
        this.errored = true
        this._errorState$.next({
          subscriptions: err,
        })
        this.emitStoreInitialised()
      },
    })
  }

  private loadAllAvailablePackages() {
    forkJoin([
      this.billingHttpService.listAvailableTsdPackages(),
      this.billingHttpService.listAvailableImpulsePackages(),
      this.billingHttpService.listPackagesPerService('ems'),
      this.billingHttpService.listPackagesPerService('crs'),
    ]).subscribe({
      next: (res) => {
        this._allAvailablePackages$.next({
          tsdPackages: res[0],
          iaPackages: res[1],
          emsPackages: res[2],
          crsPackages: res[3],
        })
        this.allSubscriptionsInitialised = true
        this.emitStoreInitialised()
      },
      error: (err) => {
        this.errored = true
        this._errorState$.next({
          packages: err,
        })
        this.emitStoreInitialised()
      },
    })
  }

  private loadAvailableEndpointMetrics(deviceEndpointId: string) {
    this.billingHttpService.listAvailableEndpointMetrics(deviceEndpointId).subscribe()
  }

  private emitStoreInitialised() {
    if (
      this.billingAccountInitialised &&
      this.allSubscriptionsInitialised &&
      this.allAvailablePackagesInitialised &&
      this.availableEndpointMetricsInitialised
    ) {
      this.storeInitialised$.next(this.errored)
    }
  }

  // Wrappers
  private wrapSuccessSubscriptionsRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.loadAllSubscriptions()
          res.next(response)
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }

  // Calls to HTTP layer
  public getBillingAccount() {
    return this.billingHttpService.getBillingAccount()
  }

  public createBillingAccount(billingAccountData: BillingAccount) {
    return this.billingHttpService.createBillingAccount(billingAccountData)
  }

  public updateBillingAccount(billingAccountData: BillingAccount) {
    return this.billingHttpService.updateBillingAccount(billingAccountData)
  }

  public deleteBillingAccount() {
    return this.billingHttpService.deleteBillingAccount()
  }

  public createSubscription(simplePackage: SimplePackage) {
    return this.wrapSuccessSubscriptionsRefresh(this.billingHttpService.createSubscription(simplePackage))
  }

  public upgradeSubscription(subscriptionId: string, bookingPackage: SimplePackage) {
    return this.wrapSuccessSubscriptionsRefresh(this.billingHttpService.upgradeSubscription(subscriptionId, bookingPackage))
  }

  public downgradeSubscription(subscriptionId: string, bookingPackage: SimplePackage) {
    return this.billingHttpService.downgradeSubscription(subscriptionId, bookingPackage)
  }

  public upgradeSubscriptionPreview(subscriptionId: string, bookingPackage: SimplePackage) {
    return this.billingHttpService.upgradeSubscriptionPreview(subscriptionId, bookingPackage)
  }

  public listAvailableTsdPackages() {
    return this.billingHttpService.listAvailableTsdPackages()
  }

  public listAvailableImpulsePackages() {
    return this.billingHttpService.listAvailableImpulsePackages()
  }

  public listTsdSubscriptions() {
    return this.billingHttpService.listTsdSubscriptions()
  }

  public listIaSubscriptions() {
    return this.billingHttpService.listIaSubscriptions()
  }

  public getEmsSubscriptions() {
    return this.billingHttpService.getEmsSubscriptions()
  }

  public listAvailableEndpointMetrics(device: DeviceInfo) {
    return this.billingHttpService.listAvailableEndpointMetrics(device.endpointId)
  }

  public setUsedMetrics(subscriptionId: string, payload: { metrics: [Record<string, any>] }) {
    return this.billingHttpService.setUsedMetrics(subscriptionId, payload)
  }

  public bulkAssignMetrics(subscriptionId: string, payload: any) {
    return this.billingHttpService.bulkAssignMetrics(subscriptionId, payload)
  }

  public setImpulseAssignedDevices(subscriptionId: string, endpointIds: string[]) {
    return this.billingHttpService.setImpulseAssignedDevices(subscriptionId, endpointIds)
  }

  public listAllSubscriptions() {
    return this.billingHttpService.listAllSubscriptions()
  }

  public cancelSubscription(subscriptionId: string) {
    return this.wrapSuccessSubscriptionsRefresh(this.billingHttpService.cancelSubscription(subscriptionId))
  }

  public listPackagesPerService(serviceId: 'tsd' | 'impulseanalytics' | 'emma' | 'crs' | 'ems') {
    return this.billingHttpService.listPackagesPerService(serviceId)
  }
}
